var wipwrp = window.wipwrp || {};

function scrollToErrorField($, input) {
	try {
		$("html, body").animate(
			{
				scrollTop:
					$(".control-group.error").first().parent().offset().top - 100,
			},
			900
		);
		$(input).css("color", "#555");
	} catch (err) {}
}

wipwrp.utils = (function ($, mzr, doc, win) {
	/* ----------------- private vars ---------------*/

	var self = {};

	/* ----------------- public methods ---------------*/

	self.checkMenu = function (ambito) {
		var _path = window.location.pathname;
    var aElement = $(ambito + ' a[href="' + _path + '"]');
    var page = $("body").attr("data-page");

		aElement.parent().addClass("active");
		var section = aElement.parent().parent().parent();
		section.addClass("is-active");
		var sectionId = "tab1";
		if (page.split("-")[0] === "aflive") {
			sectionId = "tab2";
		}
		$('div[data-section="' + sectionId + '"]').addClass("active");
		$("#" + sectionId).addClass("is-active");
	};

	self.removeButton = function (area, container, btn) {
		//wdbg.log('removeButton: area: "'+area+'" - container: "'+container+'"');
		$(area).on("click", container + " " + btn, function () {
			$(this).parents(container).remove();
			return false;
		});
	}; // removeButton();

	self.notification = function (autoRemoveOn) {
		if (autoRemoveOn === null) autoRemoveOn = false;
		//wdbg.log('wipwrp.utils: notification: autoRemoveOn: '+autoRemoveOn);
		if (autoRemoveOn === false) {
			self.removeButton("body", ".notification", ".close");
		} else {
			if ($(".notification.auto-remove").length > 0) {
				setTimeout(function () {
					$(".notification.auto-remove .close").trigger("click");
				}, 2000);
			}
		}
	}; // notification();

	self.notify = function (tgtJQObj, type, content) {
		var msg = '<a class="close" href="javascript:void(0);">×</a>' + content;
		var n = $("<div/>")
			.attr("class", "notification " + type)
			.html(msg);
		tgtJQObj.prepend(n);
		$("html, body").animate({ scrollTop: $(n.offset().top) }, 200);
	}; // notification();

	self.browserVendor = function () {
		//http://lea.verou.me/2009/02/find-the-vendor-prefix-of-the-current-browser/
		var someScript = doc.getElementsByTagName("script")[0];
		var regex = /^(Moz|Webkit|Khtml|O|ms|Icab)(?=[A-Z])/;

		for (var prop in someScript.style) {
			if (regex.test(prop)) {
				// test is faster than match, so it's better to perform
				// that on the lot and match only when necessary
				return prop.match(regex)[0];
			}
		}
		// Nothing found so far? Webkit does not enumerate over the CSS properties of the style object.
		// However (prop in style) returns the correct value, so we'll have to test for
		// the precence of a specific property
		if ("WebkitOpacity" in someScript.style) return "Webkit";
		if ("KhtmlOpacity" in someScript.style) return "Khtml";

		return "unknow";
	}; //	browserVendor();

	self.countLetters = function (inputclass) {
		$(inputclass + "[maxlength]").each(function (i) {
			$(
				'<span id="mlc_' +
					i +
					'" class="maxlength-counter">' +
					$(this).attr("maxlength") +
					"</span>"
			).insertBefore($(this));

			var _coso = {};
			_coso.$input = $(this);
			_coso.limit = $(this).attr("maxlength");
			_coso.$counter = $(".maxlength-counter#mlc_" + i);

			_coso.$input.on("keyup", function () {
				_coso.len = _coso.limit - _coso.$input.val().length;
				_coso.$counter.text(_coso.limit - _coso.$input.val().length);
				if (_coso.len < 10) {
					_coso.$counter.addClass("alert");
				} else {
					_coso.$counter.removeClass("alert");
				}
			});
		});
	};

	self.setBrowserVendor = function () {
		$("html").addClass(browserVendor() + "vendor-");
	}; //	setBrowserVendor();

	self.mobileDevice = function () {
		return !!navigator.userAgent
			.toLowerCase()
			.match(
				/(iPhone|iPod|blackberry|android 0.5|htc|lg|midp|mmp|mobile|nokia|opera mini|palm|pocket|psp|sgh|smartphone|symbian|treo mini|Playstation Portable|SonyEricsson|Samsung|MobileExplorer|PalmSource|Benq|Windows Phone|Windows Mobile|IEMobile|Windows CE|Nintendo Wii)/i
			);
	}; //	mobileDevice();

	self.randomNum = function (range) {
		if (range === null) console.error("need var range");
		var number = 1 + Math.floor(Math.random() * range);
		return number;
	}; // randomNum(range)

	self.wipTabber = function (opt) {
		if (opt.wrap === null) console.error("wipTabber needs a global wrapper ID");
		if (opt.nav === null)
			console.error("wipTabber needs a navigation UL class");
		if (opt.item === null)
			console.error("wipTabber needs a tab item class for contents");

		var item = "#" + opt.wrap + " " + opt.item;
		var nav = "#" + opt.wrap + " " + opt.nav;

		if ($(item + ".active").size() === 0) {
			$(nav + " > li:eq(0)").addClass("active");
			$(item + ":eq(0)").addClass("active");
		}
		$(item + ":not(.active)").addClass("hidden");

		$(nav + " > li:not(.disable)").on("click", function () {
			var btn = $(this);
			$(item).parent().addClass("js-loading");
			var indice = $(nav + " > li").index(btn);
			$(nav + " > li").removeClass("active");
			btn.addClass("active");
			$(item).addClass("hidden").removeClass("active");
			$(item + ":eq(" + indice + ")")
				.removeClass("hidden")
				.addClass("active");
			$(item).parent().removeClass("js-loading");
			$(win).trigger("resize");
			if ($(nav + " a").length > 0) return false;
		});
	}; // wipTabber;

	self.getQueryVar = function (variable) {
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			if (decodeURIComponent(pair[0]) == variable) {
				return decodeURIComponent(pair[1]);
			}
		}
		//console.log('Query variable %s not found', variable);
	};

	self.isScrolledIntoView = function (obj, tgtpoint) {
		var keypoint =
			tgtpoint != "undefined" && tgtpoint !== "" ? "top" : tgtpoint;
		var docViewTop =
			$(window).scrollTop() + $(window).height() - 3 * ($(window).height() / 4);
		var docViewBottom =
			$(window).scrollTop() + $(window).height() - $(window).height() / 4;
		var result;
		if (keypoint == "top") {
			var objTop = $(obj).offset().top;
			result = objTop <= docViewBottom && objTop >= docViewTop;
		} else {
			var objCenter =
				$(obj).offset().top + $(obj).outerHeight() - $(obj).outerHeight() / 2;
			result = objCenter <= docViewBottom && objCenter >= docViewTop;
		}
		return result;
	};

	self.toolTipLight = function (tipperSelector, messageSelector) {
		var distance = 12;
		var txt = "";

		jQuery(tipperSelector).hover(
			function () {
				//txt = jQuery(this).attr(messageSelector);
				txt = jQuery(this).parent("label").find(".tt-tip").html();
				jQuery("<div />").attr("class", "tip_content").appendTo("body");
				jQuery(".tip_content").html(txt).addClass("cssdirs");
			},
			function () {
				jQuery(".tip_content").remove();
			}
		);

		jQuery(document).mousemove(function (e) {
			var obj = jQuery(".tip_content");
			var top = e.pageY - distance - jQuery(".tip_content").outerHeight();
			var pos = {
				top: top < 0 ? 0 : top,
				left: e.pageX + jQuery(".tip_content").width() / 4,
			};
			obj.css(pos);
		});
	};

	self.checkLikeRadio = function () {
		if ($(".check-radiolike").length > 0) {
			$(".check-radiolike").on("change", function () {
				$('.check-radiolike[name="' + this.name + '"]')
					.filter(":checked")
					.not(this)
					.removeAttr("checked");
			});
		}
	}; // _checkLikeRadio();

	self.tooltip = function () {
		var target;
		var tooltip;
		var title;

		$("[rel~=tooltip]").on("mouseenter", function () {
			target = $(this);
			tip = target.attr("title");
			tooltip = $('<div id="tooltip"></div>');

			if (!tip || tip === "") return false;

			target.removeAttr("title");
			tooltip.css("opacity", 0).html(tip).appendTo("body");

			var init_tooltip = function () {
				if ($(win).width() < tooltip.outerWidth() * 1.5)
					tooltip.css("max-width", $(win).width() / 2);
				else tooltip.css("max-width", 340);

				var pos_left =
						target.offset().left +
						target.outerWidth() / 2 -
						tooltip.outerWidth() / 2,
					pos_top = target.offset().top - tooltip.outerHeight() - 20;

				if (pos_left < 0) {
					pos_left = target.offset().left + target.outerWidth() / 2 - 20;
					tooltip.addClass("left");
				} else tooltip.removeClass("left");

				if (pos_left + tooltip.outerWidth() > $(win).width()) {
					pos_left =
						target.offset().left -
						tooltip.outerWidth() +
						target.outerWidth() / 2 +
						20;
					tooltip.addClass("right");
				} else tooltip.removeClass("right");

				if (pos_top < 0) {
					pos_top = target.offset().top + target.outerHeight();
					tooltip.addClass("top");
				} else tooltip.removeClass("top");

				tooltip
					.css({ left: pos_left, top: pos_top })
					.animate({ top: "+=10", opacity: 1 }, 50);
			};

			init_tooltip();
			$(win).resize(init_tooltip);

			var remove_tooltip = function () {
				tooltip.animate({ top: "-=10", opacity: 0 }, 50, function () {
					$(this).remove();
				});

				target.attr("title", tip);
			};

			target.on("mouseleave", remove_tooltip);
			tooltip.on("click", remove_tooltip);
		});
	}; // tooltip();

	self.toggleSystem = function () {
		var _class, _target;
		$("[data-toggleclass]").on("click", function (e) {
			//e.preventDefault(); // non voglio prevenire il default
			_class = $(this).data("toggleclass");
			_target = $(this).data("toggletarget")
				? $($(this).data("toggletarget"))
				: $(this);
			if (_target.hasClass(_class)) {
				_target.removeClass(_class);
			} else {
				_target.addClass(_class);
			}
		});
	};

	self.lowercaseControl = function (selector) {
		$(selector).on("blur", function () {
			var _newstring = $(this).val().toLowerCase();
			$(this).val(_newstring);
		});
	};

	self.uppercaseControl = function (selector) {
		$(selector).on("blur", function () {
			var _newstring = $(this).val().toUpperCase();
			$(this).val(_newstring);
		});
	};

	self.ibanTransform = function (selector) {
		$(selector).on("blur", function () {
			var _newstring = $(this).val().replace(/ /g, "");
			_newstring = $(this)
				.val()
				.replace(/[^a-zA-Z0-9]+/g, "");
			$(this).val(_newstring);
		});
	};

	self.capitalizeControl = function (selector) {
		$(selector).on("blur", function () {
			var _newstring = $(this).val().toLowerCase();
			var _arr = _newstring.split(" ");
			_newstring = "";
			$.each(_arr, function (i) {
				_newstring +=
					_arr[i].substring(0, 1).toUpperCase() + _arr[i].substring(1) + " ";
			});
			$(this).val(_newstring.trim());
		});
	};

	self.fancyPopup = function (selector) {
		$(selector).fancybox({
			openEffect: "none",
			closeEffect: "none",
			maxWidth: 800,
			maxHeight: 400,
			padding: 25,
			tpl: {
				closeBtn:
					'<a title="Close" class="fancybox-item fancybox-close icon icon-cancel" href="javascript:;"></a>',
			},
		});
	};

	self.checkMerceologie = function (blockstep) {
		if (blockstep !== 4) return true;
		var result = true;
		if ($("[name^='merceologie[']").toArray().length > 0) {
			$(".EmptyList").removeClass("control-group error");
			$("[name^='merceologie_desc[']").each(function () {
				$(this).closest(".SummaryList").siblings(".Summary__alert--spaced").removeClass("control-group error");
				if (!$(this).val()) {
					$(this)
						.closest(".SummaryList")
						.siblings(".Summary__alert--spaced")
						.addClass("control-group")
						.addClass("error");
					scrollToErrorField($);
					result = false;
				}
			});
		} else {
			result = false;
			$(".EmptyList").addClass("control-group").addClass("error");
			scrollToErrorField($);
		}
		//Check Tag List errors
		$(".TagList").removeClass("control-group error");
		if (
			$(".TagList .error_message") &&
			$(".TagList .error_message").html() &&
			$(".TagList .error_message").html().length > 0
		) {
			$(".TagList").addClass("control-group").addClass("error");
			scrollToErrorField($);
			result = false;
		}

		//Check brands errors
		$(".Brands__description").removeClass("control-group error");
		var brandInput = $(".Brands__description input:visible");
		if (brandInput.val() != undefined && brandInput.val() == "") {
			brandInput
				.closest(".Brands__description")
				.addClass("control-group")
				.addClass("error");
			scrollToErrorField($, brandInput);
			result = false;
		}

		return result;
	};

	self.recaptchaCalculated = false;

	self.addRecaptcha = function($form){
		function fallbackRecaptchaV2($form) {
			grecaptcha.reset();
			// if the recaptcha v3 fails, fallback to recaptcha v2
			$('#add-recaptcha-here', $form).html('<div id="g-recaptcha" class="col_6 mob_12 control-group"></div>');
			grecaptcha.render('g-recaptcha', {
				'sitekey' : $('#recaptcha-script').data('site-key-v2'),
				'theme' : 'light'
			});
			self.recaptchaCalculated = true;
		}

		if (self.recaptchaIsReady && !self.recaptchaCalculated) {
			// add to the form the recaptcha check and the token to a hidden input
			try {
				grecaptcha.ready(function () {
					try {
						grecaptcha.execute($('#recaptcha-script').data('site-key'), {action: 'submit'}).then(function (token) {
							$('<input>').attr({
								type: 'hidden',
								name: 'g-recaptcha-response-v3',
							}).appendTo($form).val(token);
							self.recaptchaCalculated = true;
							$form.submit();
						}).catch(function () {
							fallbackRecaptchaV2($form);
						});
					} catch (err) {
						fallbackRecaptchaV2($form);
					}
				}).catch(function () {
					fallbackRecaptchaV2($form);
				});
			} catch (err) {
				fallbackRecaptchaV2($form);
			}
		}
	};

	/* ----------------- public methods ---------------*/

	return self;
})(jQuery, Modernizr, document, window);
